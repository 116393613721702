.rotate {
  transition: transform 0.5s;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-ccw-90 {
  transform: rotate(-90deg);
}
