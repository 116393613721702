$font-family: "Roboto", Arial, Helvetica, sans-serif;

.snippet {

  font-family: $font-family;
  font-weight: 400;

  h1 {
    margin-top: 0;
    margin-bottom: 6px;
    font-size: 16pt;
    font-weight: 700;
    letter-spacing: 0.01em;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 6px;
    font-size: 14pt;
    font-weight: 700;
    letter-spacing: 0.005em;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 6px;
    font-size: 12pt;
    font-weight: 700;
    letter-spacing: 0.005em;
  }

  p {
    margin-top: 0;
    margin-bottom: 3mm;
  }

  table {
    font-family: $font-family;
    font-weight: 400;
    width: 100%;
    font-size: 10pt;
    border: 1pt solid #ccc;
    border-collapse: collapse;

    p {
      margin-bottom: 2mm;
    }

    td, th {
      border: 0.1pt solid #ccc;
      min-width: 0.5cm;
      padding: 2mm;
      vertical-align: top;
    }
  }

  p, tr, td, th {
    page-break-inside: avoid;
  }
}

