@import 'styles/variables';

.tag {
  display: inline-block;
  cursor: default;
  opacity: 0.9;
  background-color: #333;
  color: #fff;
  font-size: 13px;
  margin: 5px 5px;
  padding: .3em .5em;
  // font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  user-select: none;
  -webkit-user-select: none;
  min-width: 40px;

  &.selectable {
    cursor: pointer;

    &:hover {
      //outline: #ccc solid 3px;
      opacity: 1.0;
    }
  }

  &.active {
    opacity: 1.0;
    outline: #ccc solid 3px;
  }

  @mixin tag($hue) {
    background-color: mat-color($primary, $hue);
    color: mat-contrast($primary, $hue);
  }

  &.tag-none {
    @include tag(100);
    border: 1px dashed #999;
  }

  &.tag-1 {
    @include tag(400);
  }

  &.tag-2 {
    @include tag(600);
  }

  &.tag-3 {
    @include tag(800);
  }
}

