@import './variables';

a.mat-menu-item {
  //box-sizing:content-box; //prevent border affecting size

  &.active-link, &.active-link:hover {
    //color: $highlight;
    //background-color: #eee;
    //border-bottom: 1px solid #aaa;
    text-decoration: underline;
    text-decoration-color: #aaa
  }
}

a.home-menu {
  padding: 0;
}

.action-menu .mat-menu-content {
  background: #eee;
}

.dropdown-header {
  background-color: #f8f8f8;
  margin: 0;
  padding: 5px 20px;
  font-size: 10px;
}

.dropdown-divider {
  height: 1px;
  margin: 3px 0;
  overflow: hidden;
  background-color: #f8f8f8;
}

nav {
  // https://stackoverflow.com/questions/4561097/css-box-shadow-bottom-only
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);

  z-index: 14;

  display: flex;
  position: relative;
  padding: 10px 16px;

  color: #999;
  height: 60px;

  .text {
    color: #000;
  }

  .trial {
    color: $red;
    text-align: right;
    margin-top: -4px;
  }

  .trial-heading {
    font-weight: bold;
    font-size: 12px;
  }

  .trial-expiry {
    font-size: 11px;
  }


  // used to right align item in nav bar
  .right {
    margin-left: auto;
  }

  .breadcrumb {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 3px;
    font-weight: 300;
  }

  a {
    color: inherit;
    text-decoration: none;
    padding: 0px 2px;
  }

  a.active-link {
    color: $highlight;
    text-decoration: none;
    border-bottom: 1px solid #aaa;
  }


  a:hover:not(.active) {
    color: #000 !important;
  }

  img {
    max-height: 30px;
  }

  ul {
    margin-top: 0;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    float: left;
    //display: inline-block;
    margin-left: 8px;
    padding-left: 16px;
    padding-top: 9px;
    padding-bottom: 7px;

    /*
    min-width: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    */
  }

  label {
    margin-bottom: inherit;
  }

  // No indent on first item in list on first list only
  > ul > li:first-of-type {
    margin-left: 0;
    padding-left: 0;
    border-left: none !important;
  }

  // secondary nav style
  &.secondary {
    z-index: 13;
    font-size: 14px;

    color: #ccc;
    background: $dark-background-2;
    height: 50px;

    padding: 8px 16px;

    li > a, li > * > a {
      &:active, &.active {
        color: #fff;
      }

      &:hover {
        color: #eee !important;
      }
    }

    li {
      border-left: solid rgba(255, 255, 255, 0.5) 1px;
      height: 35px;
      padding-top: 7px;
    }

    .text {
      color: #aaa;
    }

    .mat-form-field {
      margin-top: -5px;

      .mat-form-field-infix {
        border-top: none;
      }
    }

    .mat-checkbox-frame {
      border-color: rgba(255,255,255,0.5);
    }

  }
}

