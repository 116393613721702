.collapse {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.collapse.show {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}
