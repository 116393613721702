@media screen {
  .printonly {
    display: none;
  }

  .section-break {
    margin-top: 30px;
    border-top: 2px solid #CCC;
  }

  .section-break::after, .page-break::after {
    font-size: 11px;
    font-style: italic;
    color: #ccc;
  }

  .section-break::after {
    content: "Section Break";
  }

  .page-break::after {
    content: "Page Break";
  }
}

@media print {
  @page {
    size: auto;   /* auto is the initial value */
    margin: 15mm 20mm;
  }

  .print-footer {
    margin-top: 10px;
    width: 100%;

    p {
      font-size: 10pt;
    }
  }

  [class^="cdk-overlay"] {
    display: none !important;
  }

  .noprint {
    display: none !important;
  }

  a {
    text-decoration: none;
    word-wrap: break-word;
  }

  .section-break {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #CCC;
  }

  .page-break {
    page-break-after: always;
  }
}
