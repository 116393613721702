@import './vendor/material.theme';

$highlight: mat-color($accent);
$info:  mat-color($primary, 400);
$error:  mat-color($warn);
$alert: #e39c3e;
$nav-height: 60px;

$link: $info;
$link-hover: $highlight;

$dark-background: #373a3c;
$dark-background-1: $dark-background;
$dark-background-2: lighten($dark-background, 0%);
$dark-background-3: lighten($dark-background, 4%);

$red: #e41621;
$green: #007200;
$field: mat-color($accent, 500);
