
// Global styling for the details directive
details {
  summary {
    cursor: pointer;
  }

  & > div {
    display: none;
  }

  &[open] > div {
    display: block;
  }
}
