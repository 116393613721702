@import 'material.theme';

// Include non-theme styles for core.
@include mat-core();

$theme: mat-light-theme($primary, $accent, $warn);
@include angular-material-theme($theme);

// override tooltip margin
.mat-tooltip {
  margin-right: 5px !important;
  font-size: 11px !important;
}

.mat-dialog-container {
  max-height: 100vh;
  overflow: hidden !important;
  padding: 12px !important;
  border: solid 1px #ccc;
}

.mat-menu-panel {
  min-width: 150px !important;
}

.mat-menu-item {
  line-height: 40px !important;
  height: 40px !important;
  // color: mat-color($accent, 500);

  i {
    padding-right: 5px;
    min-width: 20px;
    text-align: center;
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.8;
}

// Calender tweaks
.mat-calendar-body-cell-content {
  cursor: pointer;
}

.mat-progress-spinner {
  width: 30px;
  height: 30px
}

form .mat-grid-tile {
  height: 100px;
}

.mat-button, .mat-raised-button {

  &:not(.addon) {
    margin: 3px 5px 3px 0 !important;
  }

  &.small {
    min-width: inherit;
  }
}


.mat-input-suffix .mat-icon-button.large {

  width: 25px !important;
  height: 25px !important;

  i {
    margin-bottom: 10px;
    font-size: 16px;
  }

}

.mat-form-field {
  width: 100%;
}
.mat-select-panel {
  max-height: 512px !important;
}

.mat-checkbox.small {
  font-size: 12px;

  .mat-checkbox-inner-container {
    height: 15px;
    width: 15px;
  }
}

.mat-checkbox-disabled {
  opacity: 0.5;
}



.mat-checkbox.invert {
  color: #fff;

  .mat-checkbox-frame {
    border-color: rgba(255, 255, 255, 0.54);
  }
}

.mat-form-field.invert {
  color: #fff;

  .mat-input-element {
    caret-color: #fff;
  }

  .mat-form-field-label, .mat-hint {
    color: #ccc;
  }

  .mat-form-field-underline {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .mat-select-value, .mat-select-arrow {
    color: #fff;
  }
}
.mat-form-field.mat-focused.invert {

  .mat-form-field-label, .mat-hint {
    color: #ccc;
  }
}

// error
.mat-error {
  ul {
    font-size: 12px;
    margin-top: 0;
    padding-inline-start: 25px;
  }
}

// Drag drop customisations
.cdk-drag-preview, .cdk-drag-placeholder {
  .drag-hide {
    display: none !important;
  }
}

.cdk-drag-preview {
  background: white;
}

.cdk-drag-placeholder {
  opacity: 0.2;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// drag drop handle
.drag-handle {
  cursor: move; // fallback for IE
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

