//vendor
@import 'styles/vendor/material.custom';

@import 'styles/variables';
@import 'styles/grid';
@import 'styles/nav';
@import 'styles/tag';
@import 'styles/table';
@import 'styles/toast';
@import 'styles/forms';
@import 'styles/collapse';
@import 'styles/rotate';
@import 'styles/document';
@import 'styles/page';
@import 'styles/clipboard';
@import 'styles/groove-widget';
@import 'styles/details';
@import 'styles/color-icon';
@import 'styles/print';
@import 'styles/preview-snippet';

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

html {
  box-sizing: border-box;
}

*, :after, :before {
  box-sizing: inherit; // https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
}


body, html {
  //overflow: hidden;
  width: 100%;
  height: 100%;
  //min-width: 970px;
  position: relative; // this is important otherwise absolutely positioned child element won't pay attention to min-width
  font-family: "Roboto", sans-serif;
  margin: 0;
  line-height: 1.5;
}

@media only screen {

  main {
    min-width: 970px;
    position:absolute;
    top:$nav-height;
    left:0;
    right:0;
    bottom:0;
  }

  .fill {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }

  .sidebar {
    background: $dark-background-1;
    width: 170px;
    height: 100%;
    z-index: 14;
    position: relative;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
  }

  .sidebar-offset {
    left: 170px;
  }

  .secondary-navbar-offset {
    top: 50px;
  }
}

@media only screen and (max-width: 1000px) {
  .sidebar {
    width: 120px;
  }
  .sidebar-offset {
    left: 120px;
  }
}

// used for error pages
.info-page {
  margin: 20px;
}


h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 6px;
  font-weight: 500;
}

h1 { font-size: 24px; }
h2 { font-size: 20px; }
h3, h4, h5, h6 {
  font-size: 16px;
}

img {
  vertical-align: middle;
  border-style: none;
}

a {
  cursor: pointer;
  color: $link;
  &:hover {
    color: $link-hover;
  }
}

.error{
  color: $error;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: $red;
  }
}

.noselect {
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-select: none; // Safari
  -moz-user-select: none; // Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none; // Non-prefixed version, currently supported by Chrome and Opera
}

.nopointer {
  pointer-events: none;
}


.text-right {
  text-align: right!important;
}

