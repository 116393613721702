// https://stackoverflow.com/questions/14007655/remove-ie10s-clear-field-x-button-on-certain-inputs
input::-ms-clear {
  display: none;
}

.dropdown-toggle {
  display: inline-block; //this ensures that whole thing is selectable
}

//dropdown toggle arrow borrowed from bootstrap
.dropdown-toggle:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  content: "";
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.form-control {
  margin-top: 10px;
  margin-bottom: 15px;

  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: block;
  font-size: 14px;
}

.form-group-inline {
  .form-control {
    display: inline-block;
    width: auto;
  }
}

.form-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 10.5px; /* this matches the mat-input label at font-size = 14px*/
  user-select: none;
}

.form-section {
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
  margin-top: 15px;
}

.mat-button, .mat-raised-button {
  margin: 5px;

  &.small {
    min-width: inherit;
  }
}









