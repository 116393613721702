.color-icon {
  display: inline-block;
  margin: 0 5px;
  height: 16px;
  width: 16px;
  position: relative;

  &.warning {
    animation: blinker 3s linear infinite;

    //border: 1px solid black;
    &:after {
      //content: "❗";
      color: black;
      content: "⚠";
      position: absolute;
      top: 0;
      left: 1px;
      font-size: 18px;
      line-height: 18px;
    }
  }

  @keyframes blinker {
    50% { background-color: #ffffff; }
  }

  i {
    font-size: 12px;
    color: #333333;
  }
}

