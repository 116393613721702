.row-container, .row {
  display: flex;
  flex-flow: row;
}

.full-height {
  //height: 100%;
  flex: 1;
}

.column-container {
  display: flex;
  flex-flow: column;
}


// basic boostrap style grid
.row {
  display: flex;
}

.col {
  flex: 1;
}

// https://stackoverflow.com/questions/12889034/sass-arithmetic
$one-span-width: 0.0821875;
$gap: 0.00125;
@for $i from 1 through 12 {
  $span-width:  ($one-span-width * $i) + ($gap * $i) ;
  .col-#{$i}{
    width: percentage($span-width);
    padding-right: 15px;
    padding-left: 15px;
  }

  .no-padding .col-#{$i} {
    padding-right: 0;
    padding-left: 0;
  }
}


