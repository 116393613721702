
// clipboard element is pushed off the bottom of the page
.clipboard-target {
  position:absolute;
  bottom:0;
  height:0;
  overflow: hidden;

  // If the nested doc is also absolute then copy text works as if it were visible
  // i.e. this tricks the browser into allowing the copy operation
  .document {
    position:absolute;
  }
}
