@media only screen {
  .A4 {
    width: 210mm;
    padding: 15mm 20mm;
  }
}

//reduce page size on smaller screens
@media only screen and (max-width: 1000px) {
  .A4 {
    width: 180mm;
    padding: 15mm 5mm;
  }
}
