// global styles for the toast container
@import './variables';


.toast-container {

  &.error {
    background: $error !important;
  }

  &.info {
    background: $info !important;
  }

  &.success {
  }

  &.alert {
    background: $alert !important;
  }
}
