@import './variables';

$preview-color: #666;

.preview-snippet {
  p {
    margin-top: 2px;
  }

  color: $preview-color;
  a { color: $preview-color }

  .highlight {
    font-weight: bold;
    color: $highlight !important;
  }
}
